import React from "react"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import RODO from "../components/RODO/rodo"

export const RODOPage = () => {


  return (
    <>
      <Navbar/>
      <RODO/>
      <Footer/>
    </>
  )
}

export default RODOPage