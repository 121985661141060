import React from "react"
import {StyledRodo,NumberedList, LetteredList} from "./rodo.styles";

export const RODO = () => {

  return (
    <StyledRodo>
      <div className="container">
        <strong>
          <p className="mb-5">Praktyka lekarska pod firmą:</p>
          <p className="mb-5">
            Specjalistyczny Gabinet Okulistyczny Agnieszka Skalska-Dziobek<br/>
            z siedzibą w Tarnowie ul.Wałowa 13/1, 33-100 Tarnów - dalej zwana „PL”.
          </p>
        </strong>

        <p className="mb-4">
          Zgodnie z art. 13 ust. 1 i ust. 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r.
          w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, dalej również „RODO”), informujemy, że:
        </p>

        <NumberedList>
          <li>administratorem Pani/Pana danych osobowych jest PL,</li>
          <li>Pani/Pana dane osobowe przetwarzane będą:
            <LetteredList>
              <li>na podstawie art. 23 i następne ustawy z dnia 6 listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta, oraz</li>
              <li>w celu udokumentowania Pani/Pana stanu zdrowia oraz udzielonych Pani/Panu świadczeń zdrowotnych,</li>
            </LetteredList>
          </li>
          <li>  odbiorcą Pani/Pana danych osobowych mogą być osoby i podmioty wskazane w art. 26 ustawy z dnia 6 listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta oraz w przepisach ustaw szczególnych,</li>
          <li>  w przypadku udzielania świadczeń opieki zdrowotnej finansowanych ze środków publicznych Pani/Pana dane osobowe przetwarzane będą:
            <LetteredList>
              <li>na podstawie art. 2 oraz art. 49 i nast. ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych, a w 		przypadkach, o których mowa w art. 12 i nast. ww. ustawy – na podstawie wskazanych przepisów szczególnych, oraz</li>
              <li>w celach weryfikacji Pani/Pana uprawnień do uzyskania świadczeń opieki zdrowotnej finansowanych ze środków publicznych, a także w celach rozliczenia tych 		świadczeń z Narodowym Funduszem Zdrowia lub innym podmiotem finansującym te świadczenia,</li>
            </LetteredList>
          </li>
          <li>w przypadku, o którym mowa w pkt 5, odbiorcą Pani/Pana danych osobowych będzie Narodowy Fundusz Zdrowia lub inny podmiot finansujący świadczenia opieki zdrowotnej,</li>
          <li>Pani/Pana dane osobowe nie będą przekazywane do państwa trzeciego/organizacji międzynarodowej,</li>
          <li>Pani/Pana dane osobowe będą przechowywane przez okresy, o których mowa w art. 29 ustawy z dnia 6 listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta oraz w przepisach ustaw szczególnych,</li>
          <li>posiada Pani/Pan prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem, z zastrzeżeniem jednak zasad gromadzenia i przetwarzania danych w dokumentacji medycznej, o których mowa w rozdziale 7 ustawy z dnia 6 listopada 2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta oraz w przepisach ustaw szczególnych,</li>
          <li>ma Pan/Pani prawo wniesienia skargi do organu nadzorczego,gdy uzna Pani/Pan, iż przetwarzanie dotyczących Pani/Pana danych osobowych narusza przepisy RODO,</li>
          <li> podanie przez Pana/Panią danych osobowych gromadzonych w dokumentacji medycznej jest obowiązkowe,</li>
          <li> Pani/Pana dane nie będą przetwarzane w sposób zautomatyzowany, w tym nie będą podlegały profilowaniu.</li>
        </NumberedList>

      </div>
    </StyledRodo>
  )
}

export default RODO