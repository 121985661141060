import styled from "styled-components"
import { breakpoints } from "../../assets/contants/breakpoints"
import { colors } from "../../assets/contants/colors"

export const StyledRodo = styled.section`
    margin-top: 50px;
    margin-bottom: 100px;
    font-size: 14px;
`
export const NumberedList = styled.ol`
   counter-reset: list;
   & > li {
       list-style: none;
       &:before {
          content: counter(list) ") ";
          counter-increment: list;
       }
   }
`

export const LetteredList = styled.ol`
   counter-reset: list;
   margin-left: 30px;
   & > li {
       list-style: none;
       &:before {
          content: counter(list, lower-alpha) ") ";
          counter-increment: list;
       }
   }
`

